/**
 *
 */
import React, { useState } from 'react';
import Box from 'photon/src/components/Box'
import Button from 'photon/src/components/Button'
import Heading from 'photon/src/components/Heading'
import IframeVideo from 'photon/src/components/IframeVideo'
import Attachment from 'photon/src/components/WP/Attachment'
import {useMenu} from 'wpapi/hooks/useMenu'
import Menu from 'app/lib/components/Menu'
import RespondTo from 'photon/src/components/RespondTo'
import {
  HeroWrapper,
  HeroInner,
  HeroHeader,
  Copy,
  Logo
} from './styles'

export default (props) => {
  const [imageHovered, setImageHovered] = useState(false);
  const [videoOpened, setVideoOpened] = useState(false);

  const menu = useMenu('primary');

  const {
    logo,
    copy,
    headline,
    background,
    cta_image,
    cta_image_hovered,
    cta_link
  } = props;

  return (<HeroWrapper
    style={{background}}
  >

    <HeroHeader>
      <RespondTo
        mobile={() => (<>
          <Logo>
            <Attachment attachment={logo} size={'medium'} />
          </Logo>
        </>)}
        tablet={() => (<>
          <Logo>
            <Attachment attachment={logo} size={'medium'} />
          </Logo>
        </>)}
        desktop={() => (<>
          <Logo>
            <Attachment attachment={logo} size={'medium'} />
          </Logo>
          <Menu items={menu} stacked={false} />
        </>)}
      />
    </HeroHeader>
    
    <HeroInner>

      {headline &&
        <Heading dangerouslySetInnerHTML={{
          __html: headline
        }}
      />}

      {copy && <Copy
        dangerouslySetInnerHTML={{__html: copy}}
      />}

      {cta_link && 
        <Box
          onClick={() => setVideoOpened(!videoOpened)}
          onMouseOver={() => setImageHovered(true)}
          onMouseOut={() => setImageHovered(false)}
        >
          <Button
            unstyled
            url={cta_link}
          >
            <Attachment
              attachment={
                (imageHovered && cta_image_hovered) ?
                  cta_image_hovered : 
                  cta_image
              }
              size={'thumbnail'}
            />
          </Button>
        </Box>}

    </HeroInner>

    {videoOpened &&
      <IframeVideo 
        url={cta_link}
        modal={true}
        onCloseCB={() => setVideoOpened(false)}
      />}

  </HeroWrapper>);
}
