import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Image from 'photon/src/components/Image'
import Heading from 'photon/src/components/Heading'

import {getTheme, respondTo} from 'photon'

const media = getTheme('media')

export const SectionWrapper = styled(Box)`
  padding: 0 40px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  min-height: 100vh;
  width: 100vw;

  ${props => props.backgroundColor &&
    `background-color: ${props.backgroundColor};`
  }

  position: relative;

  ${respondTo(media[0])} {
    padding: 0 100px;
  }
`

export const Copy = styled(Box)`
position: relative;
z-index: 3;
  max-width: 200px;
  margin-bottom: 80px;
  margin-top: 40px;

  p {
    font-size: 1.1em;
  }

  ${respondTo(media[0])} {
    max-width: 400px;

    p {
      font-size: 1.5em;
    }
  }

  ${respondTo(media[1])} {
    max-width: 450px;
  }
`

export const Headline = styled(Heading)`
position: relative;
z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  line-height: 0.85;
  font-size: 3em;
  word-break: break-word;

  height: 180px;

  ${respondTo(media[0])} {
    font-size: 4em;
    height: 240px;
  }

  ${respondTo(media[1])} {
    font-size: 6em;
    height: 240px;
  }
`

export const Tagline = styled(Box)`
position: relative;
z-index: 3;

  img {
    width: auto;
    height: 100%;
    max-height: 40px;

    ${respondTo(media[0])} {
      max-height: 60px;
    }

    ${respondTo(media[1])} {
      max-height: 85px;
    }
  }

`

export const MobileIcon = styled(Box)`

  ${props => props.hasNumber ? `
    position: absolute;
    top: 0px;
    right: 26.5px;
    height: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  ` :
  `
    display: flex;
    align-items: center;
    justify-content: center;
  `}

  ${respondTo(media[1])} {
    display: none;
  }
`

export const MobileIconImage = styled(Image)`
  max-width: 110px;
  opacity: 0.45;
`
