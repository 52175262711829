import { useState, useEffect } from 'react';

const __initialState = {x: 0, y: 0}

export const useWindowScroll = () => {

  const [scrolled, setScrolled] = useState(__initialState)

  useEffect(() => {

    const _onScroll = (e) => {
      const {scrollX, scrollY} = window;
      setScrolled({x: scrollX, y: scrollY})
      // const _to = setTimeout(() => {
        // clearTimeout(_to);
        // setScrolled({x: scrollX, y: scrollY})
      // }, 30);
    }

    window.addEventListener('scroll', _onScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', _onScroll, {passive: true});
    }
  }, [])

  return scrolled
}
