import React, {useState} from 'react';
// import AppRouter from './router'
import Spectra from 'spectra'
import './styles/main.scss'
import Header from './parts/Header'
import Home from './screens/Home'
// import Footer from './parts/Footer'
import SectionContext from "app/lib/contexts";

const firebaseConfig = {
  apiKey: "AIzaSyDsP3NnnqIasPV-CI35wwHVL5QQ-WUYY0w",
  authDomain: "yelloh-d1438.firebaseapp.com",
  projectId: "yelloh-d1438",
  storageBucket: "yelloh-d1438.appspot.com",
  messagingSenderId: "370377581583",
  appId: "1:370377581583:web:2d0d9cf6c4b91f0e5aaf7a",
  measurementId: "G-B8D7JT481C"
};

function App() {
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const [sectionNumber, setSectionNumber] = useState('1');

  return (<SectionContext.Provider value={{
    backgroundColor,
    setBackgroundColor,
    sectionNumber,
    setSectionNumber,
  }}>
    <Spectra config={firebaseConfig}>
      <Header />
      <Home />
    </Spectra>
  </SectionContext.Provider>);
}

export default App;
