import styled from 'styled-components'
import {useTheme} from 'photon'

export const LoaderWrapper = styled.div.attrs(props => ({
  className: `photon-loader`
}))`

  @keyframes infinite-spinning {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  display: ${props => props.inline ?
    `inline-block` :
    `block`
  };

  text-align: ${props => props.align ?
    props.align :
    `center`
  };

  font-size: ${props => props.size || '16px'};

  > span {
    width: 1em;
    height: 1em;
    display: inline-block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${useTheme('lodingIcon')});
    animation: infinite-spinning ${props => props.speed || '1s'} infinite linear forwards;
  }

  box-sizing: border-box;
`
