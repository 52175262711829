import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Link from 'photon/src/components/Link'
import Image from 'photon/src/components/Image'
import Heading from 'photon/src/components/Heading'

import {getTheme, respondTo} from 'photon'

const media = getTheme('media')

export const SectionWrapper = styled(Box)`
  padding: 0 40px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 100vh;
  width: 100vw;

  ${props => props.backgroundColor &&
    `background-color: ${props.backgroundColor};`
  }

  position: relative;

  ${respondTo(media[0])} {
    padding: 0 100px;
  }

  ${respondTo(media[1])} {
    justify-content: flex-start;
  }
`

export const Copy = styled(Box)`
  max-width: 230px;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
  z-index: 3;

  p {
    font-size: 1.1em;
    font-family: "Poppins";
    font-size: 20px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #1a1818;
  }

  ${respondTo(media[0])} {
    max-width: 369px;

    p {
      /* font-size: 1.5em; */
    }
  }

  ${respondTo(media[1])} {
    /* max-width: 450px; */
  }
`

export const Headline = styled(Heading)`
position: relative;
z-index: 3;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin: 0;
  line-height: 0.85;
  font-size: 3em;
  word-break: break-word;

  margin-top: 35px;

  /* height: 180px; */

  ${respondTo(media[0])} {
    font-size: 4em;
    height: 150px;
  }

  ${respondTo(media[1])} {
    font-size: 6em;
    margin-top: 100px;
    /* height: 240px; */
  }

  ${respondTo(media[2])} {
    font-size: 8em;
    margin-top: 20vh;
    /* height: 240px; */
  }
`

export const Tagline = styled(Box)`
  width: 212.1px;
  border-top: solid 0.7px #1a1818;
  opacity: 0.75;
  position: relative;
  z-index: 3;

  p {
    margin: 15.7px auto 0 auto;
    /* opacity: 0.75; */
    font-family: Poppins;
    font-size: 15.1px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #1a1818 !important;
    margin-bottom: 13px;

    ${respondTo(media[0])} {
      /* max-height: 60px; */
      font-size: 17px;
    }

    ${respondTo(media[1])} {
      /* max-height: 85px; */
    }
  }

  ${respondTo(media[0])} {
    width: 321px;
  }
`

export const MobileIcon = styled(Box)`

  ${props => props.hasNumber ? `
    position: absolute;
    top: 0px;
    right: 26.5px;
    height: 130px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  ` :
  `
    display: flex;
    align-items: center;
    justify-content: center;
    width: 275px;
  `}

  ${respondTo(media[1])} {
    display: none;
  }
`

export const MobileIconImage = styled(Image)`
  max-width: 100%;
  opacity: 0.45;
`

export const SocialBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  z-index: 3;
`

export const SocialLink = styled(Link)`
  width: 30px;
  margin: 0 5px;
`

export const SocialIcon = styled(Image)`

`
