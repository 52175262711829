import variables from './variables'

export default {
  h1: {
    fontSize: '4em',
  },
  h2: {
    fontSize: '3em',
  },
  h3: {
    fontSize: '2em',
  },
  h4: {
    fontSize: '1.8em',
  },
  h5: {
    fontSize: '1.3em',
  },
  h6: {
    fontSize: '1em',
    textTransform: 'uppercase',
  },

  p: {
    color: variables.colors.black,
  }
}
