const _colors = {
  // colors
  white: "#FFFFFF",
  offWhite: "#FCFCFC",
  gray: "slategray",
  lightGray: "lightgray",
  black: "#222222",
  black20pc: "rgba(0,0,0,0.2)",
  black50pc: "rgba(0,0,0,0.5)",
  red: "tomato",
  orange: '#f5af19b3',
  purple: "#5268db",
  lightPurple: '#7d81e0',
  darkPurple: '#5d6ddc',
  yellow: '#ffcd00',
}

export default {
  colors: {
    ..._colors,
    //
    primary: _colors.purple,
    secondary: _colors.red,
    accent: _colors.darkPurple,
  },
  media: [
    "600px", // mobile max
    "1024px", // tablet max
    "1200px", // laptop max
    "1800px", // desktop max
    "2500px", // xl max
  ]
}
