import styled from 'styled-components'
import {getTheme} from 'photon'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'
// import Image from 'photon/src/components/Image'

const media = getTheme('media', []);

export const PageWrapper = styled(Container)`
  padding: 5vw 3vw;

  @media screen and (min-width: ${media[0]}) {
    padding: 5vw;
  }

  @media screen and (min-width: ${media[1]}) {
    padding: 5vw 0;
  }
`;

export const SidebarWrapper = styled(Box)`
  display: flex;

  @media screen and (max-width: ${media[0]}) {
    flex-direction: column;
  }
`;

export const Sidebar = styled(Box)`
  width: 100%;

  @media screen and (min-width: ${media[0]}) {
    width: 25%;
  }

  @media screen and (min-width: ${media[1]}) {
    width: 30%;
  }
`;

export const Content = styled(Box)`
  flex: 1;

  @media screen and (min-width: ${media[0]}) {
    padding-right: 3vw;
  }
`;
