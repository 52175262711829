/**
 *
 */
import React from 'react';
import PageTitle from 'photon/src/components/WP/PageTitle'
import PageContent from 'photon/src/components/WP/PageContent'
import ModuleFactory from 'app/lib/factories/ModuleFactory'

import {
  PageWrapper,
  SidebarWrapper,
  Sidebar,
  Content,
} from './styles'

export default ({
  acf,
  title,
  content,
  modulesPosition
}) => {

  return (<PageWrapper>

    <PageTitle as={'h1'} title={title} />

    {acf &&
      'top' === modulesPosition &&
      <ModuleFactory modules={acf.modules} />
    }

    <SidebarWrapper>
      <Content>
        <PageContent content={content} />
      </Content>
      <Sidebar>

      </Sidebar>
    </SidebarWrapper>

    {acf &&
      'bottom' === modulesPosition &&
      <ModuleFactory modules={acf.modules} />
    }
  </PageWrapper>);
}
