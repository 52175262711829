import React from 'react'

import {
  LinkWrapper
} from './styles'

export default function Link(props) {
  const {href, alt} = props
  let newProps = {...props};

  if (!href && props.url) {
    newProps.href = props.url
  }

  if (!alt && props.title) {
    newProps.alt = props.title
  }

  return <LinkWrapper {...newProps}/>
}
