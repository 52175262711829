/**
 *
 */
import React from 'react';
import Image from 'photon/src/components/Image'
// import PlaceholderImage from 'photon/src/components/PlaceholderImage'

export default ({attachment, size, placeholder}) => {
  size = size || 'thumbnail'

  if (!attachment) return null;

  return (<>
    {attachment.sizes && attachment.sizes[size] &&
      <Image
        src={attachment.sizes[size]}
        title={attachment.title}
        alt={attachment.alt}
      />
    }
  </>);
}
