/**
 *
 */
import React from 'react';
import PageTitle from 'photon/src/components/WP/PageTitle'
import PageContent from 'photon/src/components/WP/PageContent'
import ModuleFactory from 'app/lib/factories/ModuleFactory'

import {
  PageWrapper,
} from './styles'

export default ({
  acf,
  title,
  content,
  modulesPosition
}) => {

  return (<PageWrapper>

    <PageTitle as={'h1'} title={title} />

    {acf &&
      'top' === modulesPosition &&
      <ModuleFactory modules={acf.modules} />
    }

    <PageContent content={content} />

    {acf &&
      'bottom' === modulesPosition &&
      <ModuleFactory modules={acf.modules} />
    }
  </PageWrapper>);
}
