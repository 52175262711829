/**
 *
 */
import React from 'react';
import FullWidth from 'app/lib/templates/FullWidth'
import LeftSidebar from 'app/lib/templates/LeftSidebar'
import RightSidebar from 'app/lib/templates/RightSidebar'
import BoxedContent from 'app/lib/templates/BoxedContent'

export default ({
  title,
  content,
  acf,
  template
}) => {

  // const {modules_placement} = acf

  let tmplName = 'full-width'
  if ('' !== template) {
    // removes 'page-' & '.php' from the template name
    tmplName = template.substr(0, template.indexOf('.php')).substr(5)
  }
  const tmplComponent = templateFactory[tmplName];

  return (<>
    {tmplComponent && tmplComponent({
      acf,
      title,
      content,
      // modulesPosition: modules_placement || 'bottom'
    })}
  </>)
}

const templateFactory = {
  'full-width': (tmpl) => (<FullWidth {...tmpl} />),
  'left-sidebar': (tmpl) => (<LeftSidebar {...tmpl} />),
  'right-sidebar': (tmpl) => (<RightSidebar {...tmpl} />),
  'boxed-content': (tmpl) => (<BoxedContent {...tmpl} />),
}
