import styled from 'styled-components'
import {getTheme} from 'photon'
import Container from 'photon/src/components/Container'
// import Image from 'photon/src/components/Image'

const media = getTheme('media', []);

export const PageWrapper = styled(Container)`
  padding: 5vw 3vw;

  @media screen and (min-width: ${media[0]}) {
    padding: 5vw;
  }

  @media screen and (min-width: ${media[1]}) {
    padding: 5vw 0;
  }
`;
