import loadingIcon from './assets/loading.svg'
// import logoSrc from 'app/assets/logo/photon-logo.svg'
import variables from './variables'
import typography from './typography'
import forms from './forms'

export default {
  ...variables,
  contentWidths: [
    "100%", // mobile
    "95%", // tablet max
    "1000px", // laptop max
    "1200px", // desktop max
    // "", // xl max
  ],
  lodingIcon: loadingIcon,
  logo: {
    src: 'https://wordpress.photon.software/yelloh/wp-content/uploads/sites/5/2021/03/yelloh_y-09-e1616130827321.png',
    widths: ['50px'],
    alt: 'Yelloh logo',
    className: 'app-logo'
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d9dff1',
    opacity: 0.6,
  },
  //
  typography,
  // Form Elements
  ...forms
}
