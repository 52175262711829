import styled from 'styled-components'
import Image from 'photon/src/components/Image'
import FullScreen from 'photon/src/components/FullScreen'
import {getTheme, respondTo} from 'photon'

const media = getTheme('media')

export const PageWrapper = styled(FullScreen)`
  padding: 0;
  overflow: hidden;
  position: relative;
  transition: background-color 0.8s linear;

  ${props => props.background && `
    background-color: ${props.background};
  `}
`;

export const Illustration = styled(Image)`
  position: absolute;
  bottom: 5vh;
  right: 50%;
  transform: translateX(50%);
  width: auto;
  height: ${props => `calc(${props.lh}px - 75vh)`};/*calc(100vh * 6.2);*/
  z-index: 1;
  display: none;
  opacity: 0.45;
  object-fit: cover;

  ${respondTo(media[1])} {
    display: block;
  }

  ${respondTo(media[2])} {
    display: none;
  }
`;

export const IllustrationLaptop = styled(Image)`
  position: absolute;
  top: 70vh;
  right: 13%;
  width: auto;
  height: ${props => `calc(${props.lh}px - 75vh)`};/*calc(100vh * 6.2);*/
  z-index: 1;
  display: none;
  opacity: 0.45;

  ${respondTo(media[2])} {
    display: block;
  }

  ${respondTo(media[3])} {
    display: none;
  }
`;

export const IllustrationDesktop = styled(Image)`
  position: absolute;
  top: 70vh;
  right: 50%;
  transform: translateX(50%);
  width: auto;
  height: ${props => `calc(${props.lh}px - 75vh)`};/*calc(100vh * 6.2);*/
  z-index: 1;
  display: none;
  opacity: 0.45;

  ${respondTo(media[3])} {
    display: block;
  }
`;

export const IllustrationMobile = styled(Image)`
  position: absolute;
  top: 70vh;
  right: 5%;
  width: 25%;
  height: calc(100vh * 6.2);
  z-index: 1;
  display: block;

  ${respondTo(media[0])} {
    display: none;
  }
`;
