import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'
import {getTheme, respondTo} from 'photon'

const media = getTheme('media')

export const HeroWrapper = styled(Box)`
  display: flex;
  min-height: 100vh;
  width: 100vw;
  padding: 0 0 60px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${respondTo(media[0])} {
    padding: 5vw 0;
  }
`

export const HeroHeader = styled(Container)`
  position: relative;
  margin-top: 120px;
  padding: 10px 80px;

  img {
    max-width: 170px;
  }

  ${respondTo(media[1])} {
    margin-top: 0;
    margin-bottom: 80px;
    padding: 10px;

    img {
      max-width: unset;
    }
  }
`

export const HeroInner = styled(Container)`
  padding: 0 80px;

  img {
    max-width: 120px;
  }

  ${respondTo(media[1])} {
    padding: 0 10px;

    img {
      max-width: unset;
    }
  }
`

export const Copy = styled(Box)`
  font-weight: 700;
  margin-bottom: 60px;

  * {
    color: white;
    font-weight: 700;
    font-size: 1.35em;
  }

  ${respondTo(media[0])} {
    p {
      font-size: 150%;
    }
  }
`

export const Logo = styled(Box)`
  display: inline-block;
  margin-bottom: 20px;

  ${respondTo(media[0])} {
    margin-bottom: 100px;
  }
`
