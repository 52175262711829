import React from 'react';
import Loading from 'photon/src/components/Loader'
import {LoaderWrapper} from './styles'

function PageLoader(props) {
  return (<LoaderWrapper flex={1}>
    <Loading size={'3em'} />
  </LoaderWrapper>);
}

export default PageLoader
