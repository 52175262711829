import React, { useState } from 'react';
import {
  MenuWrapper,
  MenuItem,
  MobileBurger,
  MobileBurgerLine,
} from './styles'

function Menu({items, stacked}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleOnClick = (e) => {
    // get link
    const _href = e.target.getAttribute('href');
    // if includes a pount symbol
    if (String(_href).includes('#')) {
      e.preventDefault();
      // get element ID
      const _hash = _href.replace('#', '');
      const _targetEl = document.getElementById(_hash);
      // scroll to element, if it exists
      _targetEl && _targetEl.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  return (<>
    {stacked &&
      <MobileBurger 
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <MobileBurgerLine />
        <MobileBurgerLine />
        <MobileBurgerLine />
      </MobileBurger>}
    
    <MenuWrapper 
      stacked={stacked} 
      open={isOpen}
    >
      {items && items.map((item, idx) => (
        <MenuItem 
          key={item.ID} 
          stacked={stacked}
        >
          <a
            href={item.url}
            onClick={(e) => handleOnClick(e)}
          >
            {item.title}
          </a>
        </MenuItem>
      ))}
    </MenuWrapper>
  </>);
}

export default Menu;
