import React from 'react'
import Box from 'photon/src/components/Box'

export default function PageContent({content}) {

  return (
    <Box
      className={'wp-page-content'}
      dangerouslySetInnerHTML={{
        __html: content && content.rendered
      }}
    />
  )
}
