/**
 *
 */
import React, {useContext, useEffect} from 'react';
import Hero from 'app/lib/modules/Hero'
import Section from 'app/lib/modules/Section'
import ContactSection from 'app/lib/modules/ContactSection'
import SectionContext from 'app/lib/contexts'
import Box from 'photon/src/components/Box'
import SectionNumber from 'app/lib/components/SectionNumber'

export default ({modules}) => {
  // context
  const {
    sectionNumber,
    setSectionNumber,
    setBackgroundColor,
  } = useContext(SectionContext);

  // get the initial section number and background colour
  useEffect(() => {
    // console.log({modules});
    if (
      modules?.sections &&
      0 < modules.sections.length &&
      modules.sections[0].background &&
      modules.sections[0].number
    ) {
      setSectionNumber(modules.sections[0].number);
      setBackgroundColor(modules.sections[0].background);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (<>
    {modules && modules.hero &&
      <Hero {...(modules.hero)} />
    }
    {modules && modules.sections &&
      <Box style={{
        position: 'relative'
      }}>

      <SectionNumber number={sectionNumber} />

      {modules.sections.map((mod, idx) => {
        return (<Section key={idx} {...mod} />);
      })}

      {modules.contact_section &&
        <ContactSection {...modules.contact_section} />
      }
    </Box>}
  </>)
}

// const modFactory = {
//   'hero': (mod, idx) => (
//     <Hero key={idx} {...mod} />
//   ),
//   'feature_columns': (mod, idx) => (
//     <FeatureColumns key={idx} {...mod} />
//   ),
//   'media_&_copy': (mod, idx) => (
//     <Section key={idx} {...mod} />
//   ),
//   'testimonials': (mod, idx) => (
//     <Testimonials key={idx} {...mod} />
//   ),
//   'contact_form': (mod, idx) => (
//     <Contact key={idx} {...mod} />
//   ),
// }
