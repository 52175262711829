import { useState, useEffect } from 'react';

import wpapi from '../'

export const usePage = (slugOrId) => {

  const [page, setPage] = useState(null)

  useEffect(() => {

    let __OkToLoad = true;

    const _loadPage = async () => {
      const response = await wpapi.pages.getBySlug(slugOrId)
      if (response && 0 < response.length) {
        setPage(response[0])
      }
    }

    __OkToLoad && _loadPage();

    return () => {__OkToLoad = false}
  }, [slugOrId])

  return page
}
