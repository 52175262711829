import React, {useContext} from 'react'
import Image from 'photon/src/components/Image'
import {getTheme} from 'photon'
import Button from 'photon/src/components/Button'
import SectionContext from "app/lib/contexts";

const logo = getTheme('logo')

export default function Logo({link, inline}) {
  // context
  const {setSectionNumber} = useContext(SectionContext);

  const handleScrollToTop = () => {
    setSectionNumber('1')
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }

  if (link) {
    return (
      <Button 
        unstyled
        onClick={() => handleScrollToTop()}
      >
        <Image {...logo} />
      </Button>
    );
  }

  return (
    <Image 
      onClick={() => handleScrollToTop()}
      {...logo}
    />
  );
}
