import variables from './variables'

export default {
  input: {
    lineHeight: 1.5,
    padding: '0.5em',
    width: '100%',
    marginBottom: '1em',
    border: `0.125em solid ${variables.colors.lightGray}`,
    borderRadius: '0.25em',
    outline: `0`,
  },
  textarea: {
    minHeight: '10em'
  },
  button: {
    lineHeight: 1,
    padding: '0.75em 2em',
    width: 'auto',
    margin: '1em 0',
    borderWidth: `0.125em`,
    borderStyle: `solid`,
    borderRadius: '0.25em',
    outline: 0,
    transition: `all .2s ease-in-out`
  },
  buttonHover: {
    boxShadow: `0 0 0.5em ${variables.colors.lightGray}`,
  },
  buttonActive: {
     transform: 'scale(0.95)'
  },
  primaryButton: {
    borderColor: variables.colors.purple,
    backgroundColor: variables.colors.purple,
    color: variables.colors.white,
  },
  secondaryButton: {
    borderColor: variables.colors.purple,
    backgroundColor: variables.colors.white,
    color: variables.colors.purple,
  },
  warningButton: {
    borderColor: variables.colors.red,
    backgroundColor: variables.colors.red,
    color: variables.colors.white,
  },
  unstyledButton: {
    padding: '0',
    margin: 'auto',
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  unstyledButtonHover: {
    boxShadow: `unset`,
  },
  unstyledButtonActive: {
     transform: 'scale(0.95)'
  },
}
