import styled from 'styled-components'
import Box from 'photon/src/components/Box'

import {getTheme, respondTo} from 'photon'

const media = getTheme('media')

export const Number = styled(Box)`
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
  color: #F7CD47;
  font-weight: 100;
  box-sizing: border-box;

  width: 60px;
  height: 180px;
  font-size: 100px;
  padding: 10px 0 0 10px;

  transition: all .2s;

  z-index: 10;

  ${respondTo(media[0])} {
    width: 80px;
    height: 240px;
    font-size: 120px;
    padding: 30px 0 0 15px;
  }

  ${props => props.isFixed && `
    position: fixed;
    top: 70px;
  `}

  ${props => props.isVisible && `
    transform: translateY(0%);
    opacity: 1;
  `}

  ${props => ! props.isVisible && `
    transform: translateY(-100%);
    opacity: 0;
  `}
`
