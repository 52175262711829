import styled from 'styled-components'
import {getTheme, respondTo} from 'photon'
import Box from 'photon/src/components/Box'

const media = getTheme('media')
const colors = getTheme('colors', {});

export const MenuWrapper = styled.ul`
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  justify-content: center;
  align-items: left;
  align-self: flex-end;
  float: right;

  position: absolute;
  top: 70px;
  right: 0;
  left: 0;

  box-sizing: border-box;

  background: ${colors.yellow};
  transition: 0.2s;

  // desktop
  ${respondTo(media[1])} {
    position: relative;
    flex-direction: row;
    top: 0;
    right: 0;
    left: unset;
    padding: 0;
    box-shadow: unset;
    background: transparent;
    margin-top: 20px;
  }

  // stacked general
  ${props => props.stacked && `
    max-height: 0;
    overflow: hidden;
    background-color: ${colors.white};

    li {
      margin: 0;
      padding: 0.5em 1.2em 0.5em;

      border-bottom: 2px solid ${colors.black20pc};

      a {
        color: ${colors.black};
      }
    }
  `}

  // stacked and open
  ${props => (props.stacked && props.open) && `
    max-height: 85vh;
    box-shadow: 0 10px 15px 0px rgba(0,0,0,0.1);
  `}
`;

export const MenuItem = styled.li`
  margin: 0 0 12px 0;
  list-style: none;

  font-weight: bold;
  font-size: 22px;
  color: ${colors.white};

  &:last-child {
    margin: 0;
  }

  ${props => !props.stacked && `
    margin: 0 20px 0 0;

    &:after {
      content: '|';
      margin-left: 20px;
    }
  
    &:last-child {
      &:after {
        content: '';
      }
    }
  `}

  a {
    font-weight: bold;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: ${colors.white};
    text-decoration: none;
    
    &:hover {
      color: ${colors.white};
    }

    ${props => !props.stacked && `
      font-size: 22px;
    `}
  }
`;

export const MobileBurger = styled(Box)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 26px;
  display: inline-block;

  ${respondTo(media[0])} {
    // top: 70px;
  }

  ${props => props.open && `
    & > div:first-child {
      top: 0;
      opacity: 0;
    }
    & > div {
      transform: rotate(45deg);
    }
    & > div:last-child {
      top: 11px;
      transform: rotate(-45deg);
    }
  `}
`;

export const MobileBurgerLine = styled(Box)`
  background-color: ${colors.white};
  height: 6px;
  position: absolute;
  top: 10px;
  width: 30px;
  margin-bottom: 5px;
  transition: all 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &:first-child {
    top: 0;
  }

  &:last-child {
    top: unset;
    bottom: 0;
    margin-bottom: 0px;
  }
`;