import styled, { createGlobalStyle, css } from 'styled-components'
import Box from 'photon/src/components/Box'
import Container from 'photon/src/components/Container'
import {getTheme, respondTo} from 'photon'

const media = getTheme('media')
const colors = getTheme('colors')

export const GlobalStyle = createGlobalStyle`
  ${props => css(props.theme.typography)}
`

export const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: ${colors.white};
  transition: all .3s ease-in-out;
  z-index: 998;
  background: #ffcd00;


  ${respondTo(media[1])} {
    top: -100%;
    
    ${props => props.show && `
      top: 0;
    `}
  }

  ${props => props.show && `
    top: 0;
  `}
`

export const HeaderInner = styled(Container)`
  padding: 10px;
  justify-content: space-between;
  align-tems: center;

  ul {
    margin-top: 0;
    align-self: center;
  }
`

export const LogoWrapper = styled(Box)`

`
