import React from 'react';
import {
  IframeVideoContainer,
  IframeVideoWrapper,
  IframeVideoIframe,
  CloseButton,
} from './styles';

/**
 * Displays an iframe video, either inline or in a modal box
 * 
 * @param {bool} modal
 * @returns 
 */
export default function IframeVideo(props) {

  const {
    url,
    modal,
    onCloseCB,
  } = props;
  
  // prep the URL based on 
  const prepUrl = () => {
    
    // remove trailing slash
    let newUrl = url.endsWith('/') ?
      url.slice(0, -1) :
      url;

    // format vimeo link
    if (0 <= newUrl.indexOf('://vimeo')) {
      // explode by slash
      const videoID = newUrl.split('/').pop();
      // reformat the url
      newUrl = 'https://player.vimeo.com/video/' + videoID + '?portrait=0&autoplay=1&autopause=0&background=0&muted=0';
    // format youtube link
    } else if (0 <= newUrl.indexOf('://youtube')) {

    }
    return newUrl;
  }

  return (props.modal ?

    // full-screen modal
    <IframeVideoContainer
      onClick={() => onCloseCB && onCloseCB()}
      modal={modal}
    >

      <CloseButton
        unstyled
        onClick={() => onCloseCB && onCloseCB()}
      >+</CloseButton>

      <IframeVideoWrapper modal={modal}>
        <IframeVideoIframe
          src={prepUrl()}
          frameborder={'0'}
          allow={'autoplay'}
          modal={modal}
        />
      </IframeVideoWrapper>

    </IframeVideoContainer> : 

    // inline 
    <IframeVideoWrapper modal={modal}>
      <IframeVideoIframe
        src={prepUrl()}
        frameborder={'0'}
        allow={'autoplay'}
        modal={modal}
      />
    </IframeVideoWrapper>);
}
