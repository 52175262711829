import { createContext } from "react";

const SectionContext = createContext({
  sectionNumber: '1',
  setSectionNumber: (number) => {},
  backgroundColor: 'transparent',
  setBackgroundColor: (color) => {},
});

export default SectionContext;
