/**
 * Header
 */
import React, {useEffect, useState} from 'react';
import Logo from 'photon/src/components/Logo'
import {
  HeaderWrapper,
  LogoWrapper,
  HeaderInner,

  GlobalStyle
} from './styles'

import {ThemeProvider} from 'styled-components'
import photonTheme from 'photon/src/themes/photon'
import {useMenu} from 'wpapi/hooks/useMenu'
import {useWindowScroll} from 'photon/src/hooks/useWindowScroll'
import Menu from 'app/lib/components/Menu'
import RespondTo from 'photon/src/components/RespondTo'

function Header(props) {

  const menu = useMenu('primary')
  const scroll = useWindowScroll();
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (scroll && 700 < scroll.y) {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [scroll])

  return (
    <ThemeProvider theme={photonTheme}>
      <>
        <GlobalStyle />
        <HeaderWrapper show={show}>
          <HeaderInner flex={1}>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
            <RespondTo
              mobile={() => (<Menu items={menu} stacked={true} />)}
              tablet={() => (<Menu items={menu} stacked={true} />)}
              desktop={() => (<Menu items={menu} stacked={false} />)}
            />
          </HeaderInner>
        </HeaderWrapper>
      </>
    </ThemeProvider>
  );
}

export default Header
