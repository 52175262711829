/**
 * Homepage template
 *
 * @package photon
 */
import React from 'react';
import Page from '../Page'

export default (props) => {
  return (<Page slug={'home'} />);
}
