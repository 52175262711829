import styled from 'styled-components'
import Box from 'photon/src/components/Box'
import Button from 'photon/src/components/Button'
import FullScreen from 'photon/src/components/FullScreen'
import {getTheme, respondTo} from 'photon'

const media = getTheme('media');
const colors = getTheme('colors');

export const CloseButton = styled(Button).attrs(props => ({
  className: `photon-iframe-video-close-btn`
}))`
  position: fixed;
  top: 0;
  right: 0.15em;
  transform: rotate(45deg);
  font-size: 4em;
  color: ${colors.white};
  z-index: 999;

  &:hover {
    transform: rotate(45deg);
  }
`;

export const IframeVideoContainer = styled(FullScreen).attrs(props => ({
  className: `photon-iframe-video-container-fullscreen`
}))`
  position: fixed;
  top: 0;
  left: 0;
  background: ${colors.black50pc};
  z-index: 998;
`;

export const IframeVideoWrapper = styled(Box).attrs(props => ({
  className: `photon-iframe-video-wrapper`
}))`
  // general
  position: relative;
  height: 0;
  width: 100%;
  display: inline-block;
  margin: auto;
  padding-bottom: 56.25%; /* 16:9 */

  // inline
  ${props => !props.modal && ``}

  // modal
  ${props => props.modal && ``}
`;

export const IframeVideoIframe = styled.iframe.attrs(props => ({
  className: `photon-iframe-video`
}))`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  
  // tablet
  ${respondTo(media[0])} {
    max-width: 90%;
    top: 0;
    transform: translate(-50%, 0%);
  }
  
  // desktop
  ${respondTo(media[3])} {
    max-width: 70%;
  }

  // modal
  ${props => props.modal && `
    /* width = 100 / (9 / 16) = 177.777777 */
    width: 177.78vh;
    height: 100vh;

    // tablet
    ${respondTo(media[0])} {
      height: 100%;
      width: 100%;
    }

    // desktop max
    ${respondTo(media[3])} {
      height: 100vh;
    }
  `}

`;
