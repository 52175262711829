/**
 * Output a page section
 */
import React, {useEffect, useRef, useContext} from 'react';
import {useWindowScroll} from 'photon/src/hooks/useWindowScroll';
import SectionContext from "app/lib/contexts";
import {
  Copy,
  Tagline,
  Headline,
  SectionWrapper,
  MobileIcon,
  MobileIconImage,
  SocialLink,
  SocialIcon,
  SocialBox,
} from './styles'

export default (props) => {
  // context
  const {
    setSectionNumber,
    setBackgroundColor,
  } = useContext(SectionContext);
  // window scroll
  const scroll = useWindowScroll();
  // refs
  const sectionRef = useRef();
  const {
    copy,
    number,
    tagline,
    headline,
    background,
    section_id,
    mobile_icon,
    vimeo_link,
    vimeo_icon,
    linkedin_link,
    linkedin_icon,
  } = props;
  // set the current section number and background
  useEffect(() => {
    if (
      sectionRef.current &&
      (sectionRef.current.getBoundingClientRect().top + sectionRef.current.parentElement.offsetHeight) >= 0 && // scrolling up
      sectionRef.current.getBoundingClientRect().top <= 300 // scrolling down
    ) {
      setSectionNumber(number);
      setBackgroundColor(background);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);

  return (<SectionWrapper id={section_id}>

    {/* used to reference the section offset from top */}
    <div ref={sectionRef}></div>

    {mobile_icon && mobile_icon.sizes && mobile_icon.sizes.medium &&
      <MobileIcon hasNumber={!!("" !== number)}>
        <MobileIconImage src={mobile_icon.sizes.medium} />
      </MobileIcon>
    }

    <Headline
      dangerouslySetInnerHTML={{
        __html: headline
      }}
    />

    <Copy
      dangerouslySetInnerHTML={{
        __html: copy
      }}
    />

    <Tagline
      dangerouslySetInnerHTML={{
        __html: tagline
      }}
    />

    <SocialBox>
      {vimeo_link && vimeo_icon && <SocialLink url={vimeo_link}>
        <SocialIcon src={(vimeo_icon.sizes && vimeo_icon.sizes.thumbnail) || ''} />
      </SocialLink>}

      {linkedin_link && linkedin_icon && <SocialLink url={linkedin_link}>
        <SocialIcon src={(linkedin_icon.sizes && linkedin_icon.sizes.thumbnail) || ''} />
      </SocialLink>}
    </SocialBox>
  </SectionWrapper>);
}
