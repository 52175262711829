/**
 * Output the section number
 */
 import React, { useState, useEffect, useRef } from 'react';
 import {useWindowScroll} from 'photon/src/hooks/useWindowScroll';
 import {Number} from './styles.js';
 
 export default ({number}) => {
  // states
  const [isFixed, setIsFixed] = useState();
  // window scroll
  const scroll = useWindowScroll();
  // refs
  const numberRef = useRef();

  useEffect(() => {
    // update the positioning of the section number container
    if (
      numberRef.current && 
      numberRef.current.getBoundingClientRect().top <= 75
    ) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scroll]);

  return (<>
    <div ref={numberRef}></div>
    <Number 
      isFixed={isFixed}
      isVisible={'' !== number}
    >
      {number}
    </Number>
  </>)
 }
