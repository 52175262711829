/**
 *
 */
import React, { useContext, useRef } from 'react';
import PageContent from 'photon/src/components/WP/PageContent'
import ModuleFactory from 'app/lib/factories/ModuleFactory'
import SectionContext from 'app/lib/contexts'
import illustrationTablet from 'app/assets/line-tablet.svg'
import illustrationLaptop from 'app/assets/line-laptop.svg'
import illustrationDesktop from 'app/assets/line-desktop.svg'
// import illustrationMobile from 'app/assets/illustration_mobile.png'
import {
  PageWrapper,
  Illustration,
  IllustrationLaptop,
  IllustrationDesktop
} from './styles'

export default ({
  acf,
  content,
  modulesPosition
}) => {
  // context
  const {backgroundColor} = useContext(SectionContext);

  const pageRef = useRef(null);

  const lineHeight = (pageRef && pageRef.current && pageRef.current.scrollHeight) || 0;

  return (<div  ref={pageRef}>
    <PageWrapper background={backgroundColor}>
      <Illustration src={illustrationTablet} lh={lineHeight} />
      <IllustrationLaptop src={illustrationLaptop} lh={lineHeight} />
      <IllustrationDesktop src={illustrationDesktop} lh={lineHeight} />
      {
        // <IllustrationMobile src={illustrationMobile} />
      }

      {acf &&
        'top' === modulesPosition &&
        <ModuleFactory modules={acf} />
      }

      <PageContent content={content} />

      {acf &&
        <ModuleFactory modules={acf} />
      }
    </PageWrapper>
  </div>);
}
