import Posts from './models/Posts'
import Pages from './models/Pages'
import Portfolio from './models/Portfolio'
import Testimonials from './models/Testimonials'
import Menus from './models/Menus'
import Media from './models/Media'

export const wpapiConfig = {
  protocol: 'https',
  // protocol: 'http',
  // domain: 'dops.local',
  domain: 'wordpress.photon.software/yelloh',
};

export const url = `${wpapiConfig.protocol}://${wpapiConfig.domain}`;

export const base = `${url}/wp-json`;

export const namespaces = {
  wp: '/wp/v2',
  prt: '/prt/v1',
};

const wpapi = {
  base,
  ns: namespaces,
  // models
  posts: Posts,
  pages: Pages,
  portfolio: Portfolio,
  testimonials: Testimonials,
  menus: Menus,
  media: Media,
}

export default wpapi
