import React from 'react'
import Heading from 'photon/src/components/Heading'

export default function PageTitle({title, as, html}) {
  as = as || 'h1'

  if (true === html) {
    return (
      <Heading className={'wp-page-title'} as={as} dangerouslySetInnerHTML={{
        __html: title.rendered
      }} />
    );
  }

  return (
    <Heading className={'wp-page-title'} as={as}>{title.rendered}</Heading>
  );
}
