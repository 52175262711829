/**
 * Display a page
 */
import React from 'react';
import PageLoader from 'app/parts/PageLoader'
import TemplateFactory from 'app/lib/factories/TemplateFactory'
//
// import {useParams} from 'react-router-dom'
import {usePage} from 'wpapi/hooks/usePage'

export default ({slug}) => {
  // const params = useParams()
  const page = usePage(slug)

  return (!page ?
    <PageLoader /> :
    <TemplateFactory {...page} />
  )
}
